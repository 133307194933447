import { FeatureName } from "@/models/userProfile.model";

enum AppPageNames {
  index = "Speech Analytics",
  "interactions" = "Playback",
  "interaction-id" = "",
  "success-intelligence" = "Success Intelligence",
}

enum AppRoutes {
  speechAnalytics = '/',
  successIntelligence = '/success-intelligence',
  playback = '/interactions',
  playbackDetails = '/interaction',
  landing = '/landing'
}

const routesConfiguration = [
  {
    path: AppRoutes.speechAnalytics,
    featureName: null
  },
  {
    path: AppRoutes.successIntelligence,
    featureName: FeatureName.successIntelligence
  },
  {
    path: AppRoutes.playback,
    featureName: null
  },
  {
    path: AppRoutes.playbackDetails,
    featureName: null
  }
];

export { AppPageNames, AppRoutes, routesConfiguration };