export interface IUserProfile {
  fullName: string;
  portalLink: string;
  featureFlags: IFeatureFlagConfiguration[];
}

export interface IFeatureFlagConfiguration {
  name: string;
  state: FeatureFlagState;
}

export enum FeatureName {
  successIntelligence = 'R3: Success Intelligence',
  successIntelligencePerformanceTab = 'R3: Success Intelligence: Performance tab'
}

export enum FeatureFlagState {
  disabled = 'Disabled',
  enabled = 'Enabled',
  payWall = 'PayWall',
}